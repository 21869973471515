import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8c8afb9a = () => interopDefault(import('../pages/autenticar-error.vue' /* webpackChunkName: "pages/autenticar-error" */))
const _23f1716d = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _643fcd96 = () => interopDefault(import('../pages/auth/index/ChangePasswordFuncionarios.vue' /* webpackChunkName: "pages/auth/index/ChangePasswordFuncionarios" */))
const _55f1b056 = () => interopDefault(import('../pages/auth/index/ChangePasswordInstitucionales.vue' /* webpackChunkName: "pages/auth/index/ChangePasswordInstitucionales" */))
const _041125c5 = () => interopDefault(import('../pages/auth/index/LinkWallet.vue' /* webpackChunkName: "pages/auth/index/LinkWallet" */))
const _26463527 = () => interopDefault(import('../pages/auth/index/Login.vue' /* webpackChunkName: "pages/auth/index/Login" */))
const _75064871 = () => interopDefault(import('../pages/auth/index/login-funcionarios/index.vue' /* webpackChunkName: "pages/auth/index/login-funcionarios/index" */))
const _6f7961d1 = () => interopDefault(import('../pages/auth/index/login-institucional/index.vue' /* webpackChunkName: "pages/auth/index/login-institucional/index" */))
const _20dd0f88 = () => interopDefault(import('../pages/auth/index/Logout.vue' /* webpackChunkName: "pages/auth/index/Logout" */))
const _7b170356 = () => interopDefault(import('../pages/auth/index/Register.vue' /* webpackChunkName: "pages/auth/index/Register" */))
const _c109f770 = () => interopDefault(import('../pages/auth/index/ResetPassword.vue' /* webpackChunkName: "pages/auth/index/ResetPassword" */))
const _9d7f5d3e = () => interopDefault(import('../pages/digital-citizen.vue' /* webpackChunkName: "pages/digital-citizen" */))
const _21ba818b = () => interopDefault(import('../pages/digital-citizen-levels.vue' /* webpackChunkName: "pages/digital-citizen-levels" */))
const _32eb117f = () => interopDefault(import('../themes/NuevoLeon/pages/digital-services/index.vue' /* webpackChunkName: "pages/digital-services/index" */))
const _a3548874 = () => interopDefault(import('../pages/email-action-handler.vue' /* webpackChunkName: "pages/email-action-handler" */))
const _a15bceee = () => interopDefault(import('../pages/expediente.vue' /* webpackChunkName: "pages/expediente" */))
const _fe2a56a4 = () => interopDefault(import('../pages/expediente/apoderados.vue' /* webpackChunkName: "pages/expediente/apoderados" */))
const _42dd1c2d = () => interopDefault(import('../pages/expediente/appointments/index.vue' /* webpackChunkName: "pages/expediente/appointments/index" */))
const _0d1b9fd5 = () => interopDefault(import('../pages/expediente/companies.vue' /* webpackChunkName: "pages/expediente/companies" */))
const _ad48b630 = () => interopDefault(import('../pages/expediente/identidad.vue' /* webpackChunkName: "pages/expediente/identidad" */))
const _78c33688 = () => interopDefault(import('../pages/expediente/inspections/index.vue' /* webpackChunkName: "pages/expediente/inspections/index" */))
const _4e676e66 = () => interopDefault(import('../pages/expediente/payments/index.vue' /* webpackChunkName: "pages/expediente/payments/index" */))
const _44f7b6b9 = () => interopDefault(import('../pages/expediente/portadocumentos.vue' /* webpackChunkName: "pages/expediente/portadocumentos" */))
const _890c6c60 = () => interopDefault(import('../pages/expediente/appointments/_appointmentId.vue' /* webpackChunkName: "pages/expediente/appointments/_appointmentId" */))
const _07e40110 = () => interopDefault(import('../pages/expediente/inspections/_inspectionId.vue' /* webpackChunkName: "pages/expediente/inspections/_inspectionId" */))
const _f1ae1226 = () => interopDefault(import('../pages/expediente/module/_module_id/_submodule_id/index.vue' /* webpackChunkName: "pages/expediente/module/_module_id/_submodule_id/index" */))
const _cd82a99c = () => interopDefault(import('../pages/expediente/module/_module_id/_submodule_id/_details_id.vue' /* webpackChunkName: "pages/expediente/module/_module_id/_submodule_id/_details_id" */))
const _3dc1ef44 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _51a8e3b2 = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _1ab83c42 = () => interopDefault(import('../pages/forward-email.vue' /* webpackChunkName: "pages/forward-email" */))
const _4411068a = () => interopDefault(import('../pages/mercadopago.vue' /* webpackChunkName: "pages/mercadopago" */))
const _a3c9f874 = () => interopDefault(import('../pages/motordepagos.vue' /* webpackChunkName: "pages/motordepagos" */))
const _0226e0d5 = () => interopDefault(import('../pages/notice-of-privacy.vue' /* webpackChunkName: "pages/notice-of-privacy" */))
const _01207ef0 = () => interopDefault(import('../pages/persona-moral.vue' /* webpackChunkName: "pages/persona-moral" */))
const _609c517e = () => interopDefault(import('../pages/predial.vue' /* webpackChunkName: "pages/predial" */))
const _01ef7dca = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _5412f6c0 = () => interopDefault(import('../pages/server-error.vue' /* webpackChunkName: "pages/server-error" */))
const _9f3b4794 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _4991e807 = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _96c13eec = () => interopDefault(import('../pages/validacion-pago.vue' /* webpackChunkName: "pages/validacion-pago" */))
const _e3dfbade = () => interopDefault(import('../pages/verificador/index.vue' /* webpackChunkName: "pages/verificador/index" */))
const _2c28e781 = () => interopDefault(import('../pages/what-is.vue' /* webpackChunkName: "pages/what-is" */))
const _2da002ff = () => interopDefault(import('../pages/admin/catalogs/index.vue' /* webpackChunkName: "pages/admin/catalogs/index" */))
const _6578c405 = () => interopDefault(import('../pages/admin/changelog/index.vue' /* webpackChunkName: "pages/admin/changelog/index" */))
const _241d47f1 = () => interopDefault(import('../pages/admin/citas/index.vue' /* webpackChunkName: "pages/admin/citas/index" */))
const _5fd2bd05 = () => interopDefault(import('../pages/admin/dashboard/index.vue' /* webpackChunkName: "pages/admin/dashboard/index" */))
const _286381a8 = () => interopDefault(import('../pages/admin/filters/index.vue' /* webpackChunkName: "pages/admin/filters/index" */))
const _674c4996 = () => interopDefault(import('../pages/admin/inspectores/index.vue' /* webpackChunkName: "pages/admin/inspectores/index" */))
const _c31e7890 = () => interopDefault(import('../pages/admin/modules/index.vue' /* webpackChunkName: "pages/admin/modules/index" */))
const _0f9caaf2 = () => interopDefault(import('../pages/admin/payments/index.vue' /* webpackChunkName: "pages/admin/payments/index" */))
const _e112c2ce = () => interopDefault(import('../pages/admin/preventiveDelivery/index.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/index" */))
const _1b63635a = () => interopDefault(import('../pages/admin/profile/index.vue' /* webpackChunkName: "pages/admin/profile/index" */))
const _5edbc730 = () => interopDefault(import('../pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _4fc9a558 = () => interopDefault(import('../pages/auth/CompleteData.vue' /* webpackChunkName: "pages/auth/CompleteData" */))
const _691bbb08 = () => interopDefault(import('../pages/citizen/certificates/index.vue' /* webpackChunkName: "pages/citizen/certificates/index" */))
const _b7acc0b2 = () => interopDefault(import('../pages/tools/ide/index.vue' /* webpackChunkName: "pages/tools/ide/index" */))
const _6292e3e6 = () => interopDefault(import('../pages/admin/catalogs/new.vue' /* webpackChunkName: "pages/admin/catalogs/new" */))
const _4edf8dd8 = () => interopDefault(import('../pages/admin/citas/configuration/index.vue' /* webpackChunkName: "pages/admin/citas/configuration/index" */))
const _29334c6c = () => interopDefault(import('../pages/admin/forms/builder/index.vue' /* webpackChunkName: "pages/admin/forms/builder/index" */))
const _ccb76a86 = () => interopDefault(import('../pages/admin/inspectores/configuration/index.vue' /* webpackChunkName: "pages/admin/inspectores/configuration/index" */))
const _018f615f = () => interopDefault(import('../pages/admin/modules/configuration/index.vue' /* webpackChunkName: "pages/admin/modules/configuration/index" */))
const _c72fde98 = () => interopDefault(import('../pages/admin/operators/inbox.vue' /* webpackChunkName: "pages/admin/operators/inbox" */))
const _37ddc9ce = () => interopDefault(import('../pages/admin/payments/configuration/index.vue' /* webpackChunkName: "pages/admin/payments/configuration/index" */))
const _18281e80 = () => interopDefault(import('../pages/admin/preventiveDelivery/configuration/index.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/configuration/index" */))
const _62567260 = () => interopDefault(import('../pages/admin/users/configuration/index.vue' /* webpackChunkName: "pages/admin/users/configuration/index" */))
const _47b620d0 = () => interopDefault(import('../pages/citizen/certificates/verification/index.vue' /* webpackChunkName: "pages/citizen/certificates/verification/index" */))
const _7494bdc4 = () => interopDefault(import('../pages/admin/inspectores/configuration/catalog.vue' /* webpackChunkName: "pages/admin/inspectores/configuration/catalog" */))
const _6172acc7 = () => interopDefault(import('../pages/admin/preventiveDelivery/configuration/catalog.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/configuration/catalog" */))
const _135a7ced = () => interopDefault(import('../pages/citizen/certificates/form-certs/back_cert.vue' /* webpackChunkName: "pages/citizen/certificates/form-certs/back_cert" */))
const _000a1537 = () => interopDefault(import('../pages/admin/citas/configuration/office/new.vue' /* webpackChunkName: "pages/admin/citas/configuration/office/new" */))
const _e58aeece = () => interopDefault(import('../themes/NuevoLeon/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1ddbdfad = () => interopDefault(import('../pages/admin/citas/configuration/office/_officeId.vue' /* webpackChunkName: "pages/admin/citas/configuration/office/_officeId" */))
const _131b5642 = () => interopDefault(import('../pages/admin/catalogs/terms/_id.vue' /* webpackChunkName: "pages/admin/catalogs/terms/_id" */))
const _3040025c = () => interopDefault(import('../pages/admin/citas/details/_detailsId.vue' /* webpackChunkName: "pages/admin/citas/details/_detailsId" */))
const _359b325a = () => interopDefault(import('../pages/admin/forms/answers/_form/index.vue' /* webpackChunkName: "pages/admin/forms/answers/_form/index" */))
const _379ae2cd = () => interopDefault(import('../pages/admin/forms/builder/_builderid.vue' /* webpackChunkName: "pages/admin/forms/builder/_builderid" */))
const _8074f2a6 = () => interopDefault(import('../pages/admin/forms/details/_detailsid.vue' /* webpackChunkName: "pages/admin/forms/details/_detailsid" */))
const _56372108 = () => interopDefault(import('../pages/admin/inspectores/inspectionDetailsId/_inspectionDetailsId.vue' /* webpackChunkName: "pages/admin/inspectores/inspectionDetailsId/_inspectionDetailsId" */))
const _4ee1bd8d = () => interopDefault(import('../pages/admin/operators/details/_detailsid.vue' /* webpackChunkName: "pages/admin/operators/details/_detailsid" */))
const _4e7b0a37 = () => interopDefault(import('../pages/admin/preventiveDelivery/preventiveDeliveryId/_preventiveDeliveryId.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/preventiveDeliveryId/_preventiveDeliveryId" */))
const _32d72d01 = () => interopDefault(import('../pages/citizen/certificates/digital-identity/_cert.vue' /* webpackChunkName: "pages/citizen/certificates/digital-identity/_cert" */))
const _f0687b18 = () => interopDefault(import('../pages/citizen/certificates/form-certs/_cert.vue' /* webpackChunkName: "pages/citizen/certificates/form-certs/_cert" */))
const _6826bd40 = () => interopDefault(import('../pages/admin/forms/answers/_form/_answerId.vue' /* webpackChunkName: "pages/admin/forms/answers/_form/_answerId" */))
const _67830844 = () => interopDefault(import('../pages/admin/catalogs/terms/_.vue' /* webpackChunkName: "pages/admin/catalogs/terms/_" */))
const _89fe8db2 = () => interopDefault(import('../pages/admin/catalogs/_id.vue' /* webpackChunkName: "pages/admin/catalogs/_id" */))
const _005874e8 = () => interopDefault(import('../pages/admin/forms/_formid.vue' /* webpackChunkName: "pages/admin/forms/_formid" */))
const _36713522 = () => interopDefault(import('../pages/admin/modules/_moduleId/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/index" */))
const _e465c5f0 = () => interopDefault(import('../pages/admin/modules/_moduleId/playlist/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/playlist/index" */))
const _d4ed063c = () => interopDefault(import('../pages/admin/modules/_moduleId/playlist/_playlistId.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/playlist/_playlistId" */))
const _3476c35e = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/index" */))
const _594d97d6 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/inbox/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/inbox/index" */))
const _14817821 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/uploads/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/uploads/index" */))
const _2a7053ef = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/details/_detailsid.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/details/_detailsid" */))
const _1adfe129 = () => interopDefault(import('../pages/certificates/_id.vue' /* webpackChunkName: "pages/certificates/_id" */))
const _35700e92 = () => interopDefault(import('../pages/finalDocument/_finalDocument.vue' /* webpackChunkName: "pages/finalDocument/_finalDocument" */))
const _f6a13cdc = () => interopDefault(import('../pages/payments/_paymentId.vue' /* webpackChunkName: "pages/payments/_paymentId" */))
const _3e0d203f = () => interopDefault(import('../pages/playlist/_id.vue' /* webpackChunkName: "pages/playlist/_id" */))
const _43726ff6 = () => interopDefault(import('../themes/NuevoLeon/pages/modules/_module/_submodule/index.vue' /* webpackChunkName: "pages/modules/_module/_submodule/index" */))
const _34137126 = () => interopDefault(import('../themes/NuevoLeon/pages/modules/_module/_submodule/_postId.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId" */))
const _53f6ade9 = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/index.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/index" */))
const _3ad85214 = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/appointment.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/appointment" */))
const _7c7e88d3 = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/description.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/description" */))
const _64706d66 = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/request.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/request" */))
const _5561b484 = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/steps.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/steps" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/autenticar-error",
    component: _8c8afb9a,
    name: "autenticar-error"
  }, {
    path: "/auth",
    component: _23f1716d,
    name: "auth",
    children: [{
      path: "ChangePasswordFuncionarios",
      component: _643fcd96,
      name: "auth-index-ChangePasswordFuncionarios"
    }, {
      path: "ChangePasswordInstitucionales",
      component: _55f1b056,
      name: "auth-index-ChangePasswordInstitucionales"
    }, {
      path: "LinkWallet",
      component: _041125c5,
      name: "auth-index-LinkWallet"
    }, {
      path: "Login",
      component: _26463527,
      name: "auth-index-Login"
    }, {
      path: "login-funcionarios",
      component: _75064871,
      name: "auth-index-login-funcionarios"
    }, {
      path: "login-institucional",
      component: _6f7961d1,
      name: "auth-index-login-institucional"
    }, {
      path: "Logout",
      component: _20dd0f88,
      name: "auth-index-Logout"
    }, {
      path: "Register",
      component: _7b170356,
      name: "auth-index-Register"
    }, {
      path: "ResetPassword",
      component: _c109f770,
      name: "auth-index-ResetPassword"
    }]
  }, {
    path: "/digital-citizen",
    component: _9d7f5d3e,
    name: "digital-citizen"
  }, {
    path: "/digital-citizen-levels",
    component: _21ba818b,
    name: "digital-citizen-levels"
  }, {
    path: "/digital-services",
    component: _32eb117f,
    name: "digital-services"
  }, {
    path: "/email-action-handler",
    component: _a3548874,
    name: "email-action-handler"
  }, {
    path: "/expediente",
    component: _a15bceee,
    name: "expediente",
    children: [{
      path: "apoderados",
      component: _fe2a56a4,
      name: "expediente-apoderados"
    }, {
      path: "appointments",
      component: _42dd1c2d,
      name: "expediente-appointments"
    }, {
      path: "companies",
      component: _0d1b9fd5,
      name: "expediente-companies"
    }, {
      path: "identidad",
      component: _ad48b630,
      name: "expediente-identidad"
    }, {
      path: "inspections",
      component: _78c33688,
      name: "expediente-inspections"
    }, {
      path: "payments",
      component: _4e676e66,
      name: "expediente-payments"
    }, {
      path: "portadocumentos",
      component: _44f7b6b9,
      name: "expediente-portadocumentos"
    }, {
      path: "appointments/:appointmentId",
      component: _890c6c60,
      name: "expediente-appointments-appointmentId"
    }, {
      path: "inspections/:inspectionId",
      component: _07e40110,
      name: "expediente-inspections-inspectionId"
    }, {
      path: "module/:module_id?/:submodule_id",
      component: _f1ae1226,
      name: "expediente-module-module_id-submodule_id"
    }, {
      path: "module/:module_id?/:submodule_id?/:details_id",
      component: _cd82a99c,
      name: "expediente-module-module_id-submodule_id-details_id"
    }]
  }, {
    path: "/faq",
    component: _3dc1ef44,
    name: "faq"
  }, {
    path: "/forbidden",
    component: _51a8e3b2,
    name: "forbidden"
  }, {
    path: "/forward-email",
    component: _1ab83c42,
    name: "forward-email"
  }, {
    path: "/mercadopago",
    component: _4411068a,
    name: "mercadopago"
  }, {
    path: "/motordepagos",
    component: _a3c9f874,
    name: "motordepagos"
  }, {
    path: "/notice-of-privacy",
    component: _0226e0d5,
    name: "notice-of-privacy"
  }, {
    path: "/persona-moral",
    component: _01207ef0,
    name: "persona-moral"
  }, {
    path: "/predial",
    component: _609c517e,
    name: "predial"
  }, {
    path: "/reset-password",
    component: _01ef7dca,
    name: "reset-password"
  }, {
    path: "/server-error",
    component: _5412f6c0,
    name: "server-error"
  }, {
    path: "/terms-and-conditions",
    component: _9f3b4794,
    name: "terms-and-conditions"
  }, {
    path: "/terms-of-service",
    component: _4991e807,
    name: "terms-of-service"
  }, {
    path: "/validacion-pago",
    component: _96c13eec,
    name: "validacion-pago"
  }, {
    path: "/verificador",
    component: _e3dfbade,
    name: "verificador"
  }, {
    path: "/what-is",
    component: _2c28e781,
    name: "what-is"
  }, {
    path: "/admin/catalogs",
    component: _2da002ff,
    name: "admin-catalogs"
  }, {
    path: "/admin/changelog",
    component: _6578c405,
    name: "admin-changelog"
  }, {
    path: "/admin/citas",
    component: _241d47f1,
    name: "admin-citas"
  }, {
    path: "/admin/dashboard",
    component: _5fd2bd05,
    name: "admin-dashboard"
  }, {
    path: "/admin/filters",
    component: _286381a8,
    name: "admin-filters"
  }, {
    path: "/admin/inspectores",
    component: _674c4996,
    name: "admin-inspectores"
  }, {
    path: "/admin/modules",
    component: _c31e7890,
    name: "admin-modules"
  }, {
    path: "/admin/payments",
    component: _0f9caaf2,
    name: "admin-payments"
  }, {
    path: "/admin/preventiveDelivery",
    component: _e112c2ce,
    name: "admin-preventiveDelivery"
  }, {
    path: "/admin/profile",
    component: _1b63635a,
    name: "admin-profile"
  }, {
    path: "/admin/settings",
    component: _5edbc730,
    name: "admin-settings"
  }, {
    path: "/auth/CompleteData",
    component: _4fc9a558,
    name: "auth-CompleteData"
  }, {
    path: "/citizen/certificates",
    component: _691bbb08,
    name: "citizen-certificates"
  }, {
    path: "/tools/ide",
    component: _b7acc0b2,
    name: "tools-ide"
  }, {
    path: "/admin/catalogs/new",
    component: _6292e3e6,
    name: "admin-catalogs-new"
  }, {
    path: "/admin/citas/configuration",
    component: _4edf8dd8,
    name: "admin-citas-configuration"
  }, {
    path: "/admin/forms/builder",
    component: _29334c6c,
    name: "admin-forms-builder"
  }, {
    path: "/admin/inspectores/configuration",
    component: _ccb76a86,
    name: "admin-inspectores-configuration"
  }, {
    path: "/admin/modules/configuration",
    component: _018f615f,
    name: "admin-modules-configuration"
  }, {
    path: "/admin/operators/inbox",
    component: _c72fde98,
    name: "admin-operators-inbox"
  }, {
    path: "/admin/payments/configuration",
    component: _37ddc9ce,
    name: "admin-payments-configuration"
  }, {
    path: "/admin/preventiveDelivery/configuration",
    component: _18281e80,
    name: "admin-preventiveDelivery-configuration"
  }, {
    path: "/admin/users/configuration",
    component: _62567260,
    name: "admin-users-configuration"
  }, {
    path: "/citizen/certificates/verification",
    component: _47b620d0,
    name: "citizen-certificates-verification"
  }, {
    path: "/admin/inspectores/configuration/catalog",
    component: _7494bdc4,
    name: "admin-inspectores-configuration-catalog"
  }, {
    path: "/admin/preventiveDelivery/configuration/catalog",
    component: _6172acc7,
    name: "admin-preventiveDelivery-configuration-catalog"
  }, {
    path: "/citizen/certificates/form-certs/back_cert",
    component: _135a7ced,
    name: "citizen-certificates-form-certs-back_cert"
  }, {
    path: "/admin/citas/configuration/office/new",
    component: _000a1537,
    name: "admin-citas-configuration-office-new"
  }, {
    path: "/",
    component: _e58aeece,
    name: "index"
  }, {
    path: "/admin/citas/configuration/office/:officeId?",
    component: _1ddbdfad,
    name: "admin-citas-configuration-office-officeId"
  }, {
    path: "/admin/catalogs/terms/:id?",
    component: _131b5642,
    name: "admin-catalogs-terms-id"
  }, {
    path: "/admin/citas/details/:detailsId?",
    component: _3040025c,
    name: "admin-citas-details-detailsId"
  }, {
    path: "/admin/forms/answers/:form",
    component: _359b325a,
    name: "admin-forms-answers-form"
  }, {
    path: "/admin/forms/builder/:builderid",
    component: _379ae2cd,
    name: "admin-forms-builder-builderid"
  }, {
    path: "/admin/forms/details/:detailsid?",
    component: _8074f2a6,
    name: "admin-forms-details-detailsid"
  }, {
    path: "/admin/inspectores/inspectionDetailsId/:inspectionDetailsId?",
    component: _56372108,
    name: "admin-inspectores-inspectionDetailsId-inspectionDetailsId"
  }, {
    path: "/admin/operators/details/:detailsid?",
    component: _4ee1bd8d,
    name: "admin-operators-details-detailsid"
  }, {
    path: "/admin/preventiveDelivery/preventiveDeliveryId/:preventiveDeliveryId?",
    component: _4e7b0a37,
    name: "admin-preventiveDelivery-preventiveDeliveryId-preventiveDeliveryId"
  }, {
    path: "/citizen/certificates/digital-identity/:cert?",
    component: _32d72d01,
    name: "citizen-certificates-digital-identity-cert"
  }, {
    path: "/citizen/certificates/form-certs/:cert?",
    component: _f0687b18,
    name: "citizen-certificates-form-certs-cert"
  }, {
    path: "/admin/forms/answers/:form?/:answerId",
    component: _6826bd40,
    name: "admin-forms-answers-form-answerId"
  }, {
    path: "/admin/catalogs/terms/*",
    component: _67830844,
    name: "admin-catalogs-terms-all"
  }, {
    path: "/admin/catalogs/:id",
    component: _89fe8db2,
    name: "admin-catalogs-id"
  }, {
    path: "/admin/forms/:formid?",
    component: _005874e8,
    name: "admin-forms-formid"
  }, {
    path: "/admin/modules/:moduleId",
    component: _36713522,
    name: "admin-modules-moduleId"
  }, {
    path: "/admin/modules/:moduleId/playlist",
    component: _e465c5f0,
    name: "admin-modules-moduleId-playlist"
  }, {
    path: "/admin/modules/:moduleId/playlist/:playlistId",
    component: _d4ed063c,
    name: "admin-modules-moduleId-playlist-playlistId"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId",
    component: _3476c35e,
    name: "admin-modules-moduleId-submoduleId"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/inbox",
    component: _594d97d6,
    name: "admin-modules-moduleId-submoduleId-inbox"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/uploads",
    component: _14817821,
    name: "admin-modules-moduleId-submoduleId-uploads"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/details/:detailsid?",
    component: _2a7053ef,
    name: "admin-modules-moduleId-submoduleId-details-detailsid"
  }, {
    path: "/certificates/:id?",
    component: _1adfe129,
    name: "certificates-id"
  }, {
    path: "/finalDocument/:finalDocument?",
    component: _35700e92,
    name: "finalDocument-finalDocument"
  }, {
    path: "/payments/:paymentId?",
    component: _f6a13cdc,
    name: "payments-paymentId"
  }, {
    path: "/playlist/:id?",
    component: _3e0d203f,
    name: "playlist-id"
  }, {
    path: "/modules/:module?/:submodule",
    component: _43726ff6,
    name: "modules-module-submodule"
  }, {
    path: "/modules/:module?/:submodule?/:postId",
    component: _34137126,
    children: [{
      path: "",
      component: _53f6ade9,
      name: "modules-module-submodule-postId"
    }, {
      path: "appointment",
      component: _3ad85214,
      name: "modules-module-submodule-postId-appointment"
    }, {
      path: "description",
      component: _7c7e88d3,
      name: "modules-module-submodule-postId-description"
    }, {
      path: "request",
      component: _64706d66,
      name: "modules-module-submodule-postId-request"
    }, {
      path: "steps",
      component: _5561b484,
      name: "modules-module-submodule-postId-steps"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
